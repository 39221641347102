.mobile-logo{
    display: none;
}
@media (max-width: 430px) {
    .mobile-logo {
        display: block;
        width: 100%;
        height: auto;
        max-width: 200px;
        margin-top: 1em;
    }
}